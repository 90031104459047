<template>
  <!-- 为您推荐 -->
  <div v-if="videoList && videoList.length">
    <div class="u-title"><span class="u-title-txt">精彩视频</span></div>
    <div class="m-list2 video_wrap" v-if="videoList && videoList.length">
      <Row :gutter="16">
        <Col :key="'m-list2-1' + index" span="6" v-for="(item, index) in videoList">
          <div class="m-list2-box">
            <div class="ml2-item">
              <div class="ml2-item-box" style="padding-top: 56.4102564%" @contextmenu.prevent="rightClcik()">
                <video
                  controlsList="nodownload"
                  class="video_box"
                  :src="item.url"
                  :poster="item.cover"
                  controls="controls"
                  style="max-width: 100%"
                >您的浏览器不支持 video 标签。</video>
                <!-- <videoPlayer :cover="item.cover" :index="index" :ref="'videoplay'" :url="item.url" @onPlayerPlay="onPlayerPlay"></videoPlayer> -->
              </div>
            </div>
            <div class="ml2-item-info" style="height: 50px;">
              <p>{{ item.title }}</p>
            </div>
          </div>
        </Col>
      </Row>
      <div class="line">
        <div class="load-more" v-if="totalCount > videoList.length" @click="loadMore">查看更多</div>
      </div>
    </div>
    <!-- <div class="u-empty" v-else>暂无数据</div> -->
    <div class="u-empty" v-if="loading && !videoList.length">数据加载中...</div>
    <div class="u-empty" v-else-if="!loading && !videoList.length">暂无数据</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import videoPlayer from "@components/videoPlayer/videoPlayer.vue";
export default {
  name: "more-video",
  components: {
    videoPlayer,
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 8,
      totalCount: 0,
      videoList: [], //精彩视频
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    videoOption() {
      let query = `
        query($query:QueryInput!){
          exhibitionVideoQuery{
            query(query:$query){
              hasNextPage
              indexFrom
              pageIndex
              pageSize
              totalCount
              items{
                cover
                coverIndex
                createAt
                title
                url
                id
                showIndex
              }
            }
          }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let order = [
        {
          N: "showIndex",
          v: 0,
        },
        {
          N: "updateAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      return opt;
    },
  },
  created() {
    this.videoInit(); //精彩视频初始化
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    async videoInit() {
      this.loading = true;
      let data = await this.graphqlPost(this.videoOption);
      let res = JSON.parse(JSON.stringify(data.data.exhibitionVideoQuery.query));
      if (this.videoList.length) {
        this.videoList = [...this.videoList, ...res.items]; 
      } else {
        this.videoList = res.items;
      }
      this.totalCount = res.totalCount; 
      this.loading = false;
    },
    onPlayerPlay(index) {
      let arr = this.$refs.videoplay;
      for (let i = 0; i < arr.length; i++) {
        if (i != index && arr[i]) {
          arr[i].pause();
        }
      }
    },
    loadMore() {
      this.pageIndex += 1;
      this.videoInit();
    },
    rightClcik(){
      // console.log('右键了')
    }
  },
};
</script>

<style lang="scss" scoped>
.video_wrap {
  .m-list2-box {
    float: initial;
    .ml2-item {
      padding: 0;
      .ml2-item-box {
        border: none;

        .video_box {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .ml2-item-info {
      padding: 10px 0;
    }
  }
  &.m-list2 {
    padding-top: 0;
  }
  .line {
    padding: 10px;
    .load-more {
      text-align: center;
      cursor: pointer;
      width: 70px;
      margin: 0 auto;
    }
  }
}
</style>
